<template>
  <div>
    <v-card class="pa-4">
      <p class="text-2xl ma-5 mb-9">Créer/Editer un objectif</p>
      <v-row class="mx-1 my-3">
        <v-card-text>
          <v-form ref="siteForm">
            <v-row>
              <v-col cols="12" sm="12">
                <sites-filter
                  :initialValue="selectedSites"
                  @valueChanged="updateSites"
                />
              </v-col>
              <v-col cols="12" sm="12">
                <date-filter
                  :initialValue="date"
                  format="month"
                  :isRange="false"
                  @valueChanged="updateDate"
                />
              </v-col>

              <v-col cols="12" sm="12">
                <v-autocomplete
                  outlined
                  v-model="selectedObjectiveType"
                  :items="objectiveTypes"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Type d'objectif"
                  item-text="name"
                  item-value="id"
                  :rules="[valueSelected]"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="value"
                  label="Valeur"
                  outlined
                  type="number"
                  :rules="[required]"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="saveSite"
                  >Enregistrer l'objectif</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  @click.native="$emit('openDialog', false)"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
    <v-snackbar v-model="successAlert" timeout="2000" top color="success"
      >Objectif mis à jour</v-snackbar
    >
  </div>
</template>

<script>
import axios from "@axios";
import { mdiCalendar } from "@mdi/js";
import DateFilter from "@/components/common/filters/DateFilter";
import SitesFilter from "@/components/common/filters/SitesFilter";
import { required, valueSelected } from "@core/utils/validation";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "ObjectivesEdit",
  components: {
    DateFilter,
    SitesFilter,
  },
  data() {
    return {
      required,
      valueSelected,
      successAlert: false,
      menu: false,
      date: "",
      selectedSites: [],
      icons: {
        mdiCalendar,
      },
      value: 0,
      selectedObjectiveType: 0,
      objectiveTypes: [],
    };
  },
  async created() {
    const { data } = await axios.get("/objectives/types/");
    this.objectiveTypes = data;
  },
  mounted() {
    saveRmraView(this.$route);
  },
  methods: {
    async saveSite() {
      const isFormValid = this.$refs.siteForm.validate();
      if (!isFormValid) return;

      const { status } = await axios.post(
        "/objectives/",
        {},
        {
          params: {
            sites: this.selectedSites.join(","),
            date: this.date,
            objtype: this.selectedObjectiveType,
            value: this.value,
          },
        }
      );
      if (status === 200) this.successAlert = true;
    },
    updateDate(newDate) {
      this.date = newDate;
    },
    updateSites(newSites) {
      this.selectedSites = newSites;
    },
  },
};
</script>

<style scoped>
[role="menu"] {
  top: 357px !important;
  right: 0 !important;
}
</style>
